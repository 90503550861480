var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-panel',[_c('ts-panel-wrapper',[_c('fieldset',{staticClass:"mt-2"},[_c('legend',{staticClass:"text-blue"},[_vm._v(" "+_vm._s(_vm.$t('loanRepayment.repaymentInfo'))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row mb-2 mt-2"},[_c('div',{staticClass:"col-lg-4"},[_c('label',{staticClass:"form-col-label control-label required"},[_vm._v(_vm._s(_vm.$t( 'loanRepayment.actualpaymentDate' )))]),_c('DatePicker',{attrs:{"value":_vm.model.actual_payment_date,"placeholder":"DD-MM-YYYY","format":"dd-MM-yyyy","options":_vm.options},on:{"on-change":_vm.onChangeActualPaymentDate}}),(_vm.errors.has('actual_payment_date'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('actual_payment_date'))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-lg-4"},[_c('label',{staticClass:"form-col-label control-label required"},[_vm._v(_vm._s(_vm.$t('loanRepayment.cyclePaymentDate')))]),_c('DatePicker',{attrs:{"value":_vm.model.cycle_payment_date,"placeholder":"DD-MM-YYYY","format":"dd-MM-yyyy"},on:{"on-change":_vm.onChangeCyclePaymentDate}}),(_vm.errors.has('cycle_payment_date'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_payment_date'))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-lg-4"},[_c('label',{staticClass:"form-col-label control-label required"},[_vm._v(_vm._s(_vm.$t('loanRepayment.paymentType')))]),_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.model.payment_type),expression:"model.payment_type",modifiers:{"number":true}}],staticClass:"form-select form-select-sm",class:{
                                        'is-invalid': _vm.errors.has(
                                            'payment_type'
                                        )
                                    },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.$set(_vm.model, "payment_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.fetchRepayment]}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t('select')))]),_vm._l((_vm.paymentType),function(option,index){return _c('option',{key:index,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.label)+" ")])})],2),(_vm.errors.has('payment_type'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('payment_type'))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-2"},[_c('ts-loading-banner',{attrs:{"loading":_vm.loanLoading}},[_c('Select',{class:{
                                        'ivu-form-item-error': _vm.errors.has(
                                            'loan_id'
                                        )
                                    },attrs:{"remote-method":_vm.loadLoanDisbursement,"filterable":"","loading":_vm.loanLoading,"clearable":"","placeholder":_vm.$t(
                                            'loanRepayment.searchCustomerNameOrLoanNumber'
                                        )},on:{"on-change":_vm.fetchRepayment},model:{value:(_vm.model.loan_id),callback:function ($$v) {_vm.$set(_vm.model, "loan_id", $$v)},expression:"model.loan_id"}},_vm._l((_vm.loanDisbursementsList),function(option,index){return _c('Option',{key:index,attrs:{"value":option.loan_id,"label":option.loan_number}},[_vm._v(" "+_vm._s(option.loan_number)+" | "+_vm._s(option.customer.customer_code)+" | "+_vm._s(option.customer.customer_name_en)+" | "+_vm._s(option.customer.customer_name_kh)+" | "+_vm._s(option.customer.contact_no)+" ")])}),1)],1),(_vm.errors.has('loan_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('loan_id'))+" ")]):_vm._e()],1),_c('div',{staticClass:"demo-spin-article"},[_c('div',{staticClass:"tw-overflow-auto",staticStyle:{"height":"45vh"}},_vm._l((_vm.repaymentItems),function(item,index){return _c('div',{key:index,staticClass:"list-group-item list-group-item-action d-flex"},[_c('div',{staticClass:"me-1 fs-14px"},[_c('ts-checkbox',{attrs:{"disabled":index == 0},model:{value:(item.is_pay),callback:function ($$v) {_vm.$set(item, "is_pay", $$v)},expression:"item.is_pay"}})],1),_c('div',{staticClass:"flex-fill"},[_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('div',{staticClass:"fs-16px lh-12 mb-2px fw-bold text-dark"},[_vm._v(" "+_vm._s(_vm.$root.$i18n.locale === 'kh' ? item.day_kh : item.day_en)+" "+_vm._s(item.cycle_payment_date)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t( 'loanRepayment.isOweCyclePaid' ))+": "),(
                                                        item.is_owe_cycle_paid
                                                    )?_c('span',{staticClass:"badge bg-warning"},[_vm._v(_vm._s(_vm.$t('yes')))]):_c('span',{staticClass:"badge bg-secondary"},[_vm._v(_vm._s(_vm.$t('no')))])])]),_c('div',{staticClass:"fs-12px"},[_c('div',{staticClass:"text-gray-600 flex-1"},[_vm._v(" "+_vm._s(_vm.$t( 'loanRepayment.amountExcess' ))+": "+_vm._s(_vm.formatCurrencyWithCode( item.amount_excess, _vm.currency ))+" ")])]),(_vm.model.payment_type == 3)?_c('div',{staticClass:"mb-1 fs-12px"},[_c('div',{staticClass:"text-gray-600 flex-1"},[_vm._v(" "+_vm._s(_vm.$t( 'loanRepayment.payOffPenalty' ))+": "+_vm._s(_vm.formatCurrencyWithCode( item.pay_off_penalty_amount, _vm.currency ))+" ")])]):_vm._e(),_c('div',{staticClass:"tw-flex tw-justify-between tw-uppercase tw-font-bold"},[_vm._v(" "+_vm._s(_vm.$t( 'loanRepayment.totalAmount' ))+": "),(
                                                    _vm.model.payment_type == 3
                                                )?_c('span',{staticClass:"badge bg-primary fs-14px"},[_vm._v(_vm._s(_vm.formatCurrencyWithCode( item.total_amount - item.pay_off_penalty_amount, _vm.currency )))]):_c('span',{staticClass:"badge bg-primary fs-14px"},[_vm._v(_vm._s(_vm.formatCurrencyWithCode( item.total_amount - item.amount_excess, _vm.currency )))])]),_c('hr',{staticClass:"mb-10px bg-gray-600"}),_c('div',{staticClass:"d-flex align-items-center mb-5px"},[_c('div',{staticClass:"fs-12px d-flex align-items-center text-dark fw-bold"},[_vm._v(" "+_vm._s(_vm.$t( 'loanRepayment.repaymentItems' ))+" ")]),_c('div',{staticClass:"ms-auto"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle"}],staticClass:"btn btn-outline-default text-gray-600 btn-xs rounded-pill fs-10px px-2 not-collapsed",staticStyle:{"overflow-anchor":"none"},attrs:{"href":'#accordion' + index},on:{"click":function($event){$event.preventDefault();}}},[_vm._v(" "+_vm._s(_vm.$t( 'loanRepayment.collapse' ))+" ")])])]),_c('b-collapse',{attrs:{"id":'accordion' + index,"visible":index == 0,"accordion":"my-accordion"}},[_c('div',{staticClass:"widget-list rounded"},_vm._l((_vm.itemDetails(
                                                        item.repayment_json
                                                    )),function(sub,i){return _c('a',{key:i,staticClass:"widget-list-item",attrs:{"href":"#"}},[_c('div',{staticClass:"widget-list-content"},[_c('h4',{staticClass:"widget-list-title"},[_vm._v(" "+_vm._s(i + 1)+" "+_vm._s(sub.repayment_item)+" ")])]),_c('div',{staticClass:"widget-list-action text-nowrap text-gray-600 fw-bold text-decoration-none"},[_vm._v(" "+_vm._s(_vm.formatCurrencyWithCode( sub.repayment_amount, _vm.currency ))+" ")])])}),0)])],1)])}),0),_c('div',{staticClass:"invoice"},[_c('div',{staticClass:"invoice-content"},[_c('div',{staticClass:"invoice-price"},[_c('div',{staticClass:"invoice-price-left"},[_c('div',{staticClass:"invoice-price-row"},[_c('div',{staticClass:"sub-price"},[_c('small',[_vm._v("SUBTOTAL")]),_c('span',{staticClass:"text-dark"},[_vm._v(_vm._s(_vm.formatCurrencyWithCode( _vm.subtotal - _vm.totalPayOffPenalty, _vm.currency )))])]),(
                                                        _vm.model.payment_type ==
                                                            3
                                                    )?_c('div',{staticClass:"sub-price"},[_c('small',[_vm._v("Pay-off Penalty")]),_c('span',{staticClass:"text-dark"},[_vm._v(_vm._s(_vm.formatCurrencyWithCode( _vm.totalPayOffPenalty, _vm.currency )))])]):_c('div',{staticClass:"sub-price"},[_c('i',{staticClass:"fa fa-minus text-muted"})]),_c('div',{staticClass:"sub-price"},[_c('small',[_vm._v("Amount Excess")]),_c('span',{staticClass:"text-dark"},[_vm._v(_vm._s(_vm.formatCurrencyWithCode( _vm.totalAmountExcess, _vm.currency )))])])])]),_c('div',{staticClass:"invoice-price-right"},[_c('small',[_vm._v("GRAND TOTAL")]),_c('span',{staticClass:"fw-bold"},[_vm._v(_vm._s(_vm.formatCurrencyWithCode( _vm.subtotal - _vm.totalAmountExcess, _vm.currency )))])])])])])]),(_vm.loading)?_c('Spin',{attrs:{"size":"large","fix":""}}):_vm._e(),_c('form',{staticClass:"form-horizontal form-horizontal-text-right"},[_c('div',{staticClass:"row mb-2"},[_c('label',{staticClass:"form-label col-form-label col-md-7"},[_vm._v(_vm._s(_vm.$t( 'loanRepayment.actualPaymentAmount' )))]),_c('div',{staticClass:"col-md-5"},[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('Input',{class:{
                                                'ivu-form-item-error': _vm.errors.has(
                                                    'amount_paid'
                                                )
                                            },attrs:{"placeholder":_vm.$t(
                                                    'loanRepayment.enterNumber'
                                                ),"size":"large"},model:{value:(_vm.model.amount_paid),callback:function ($$v) {_vm.$set(_vm.model, "amount_paid", $$v)},expression:"model.amount_paid"}},[(
                                                    _vm.currency.currency_id ==
                                                        1
                                                )?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(
                                                    _vm.currency.currency_id ==
                                                        2
                                                )?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(
                                                    _vm.currency.currency_id ==
                                                        3
                                                )?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.amount_paid ? _vm.formatNumber( _vm.model.amount_paid, _vm.currency.currency_id ) : _vm.$t( 'loanRepayment.enterNumber' ))+" ")])],1)],1)]),_c('div',{staticClass:"row mb-2"},[_c('label',{staticClass:"form-label col-form-label col-md-7"},[_vm._v(_vm._s(_vm.$t('loanRepayment.penaltyWaive')))]),_c('div',{staticClass:"col-md-5"},[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('Input',{class:{
                                                'ivu-form-item-error': _vm.errors.has(
                                                    'loan_amount'
                                                )
                                            },attrs:{"placeholder":_vm.$t(
                                                    'loanRepayment.enterNumber'
                                                ),"size":"large"}},[(
                                                    _vm.currency.currency_id ==
                                                        1
                                                )?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(
                                                    _vm.currency.currency_id ==
                                                        2
                                                )?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(
                                                    _vm.currency.currency_id ==
                                                        3
                                                )?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.loan_amount ? _vm.formatNumber( _vm.model.loan_amount, _vm.model.currency_id ) : _vm.$t( 'loanRepayment.enterNumber' ))+" ")])],1)],1)])])],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }