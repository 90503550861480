<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <div class="demo-spin-article">
                <div class="row">
                    <div class="col-md-5">
                        <repayment-info
                            v-model="model"
                            :validation="errors"
                            ref="repaymentInfo"
                        />
                    </div>
                    <div class="col-md-7">
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <loan-info
                                    v-model="model"
                                    :validation="errors"
                                />
                            </div>
                            <div class="col-md-6">
                                <customer-profile
                                    v-model="model"
                                    :validation="errors"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <repayment-history
                                    v-model="model"
                                    :validation="errors"
                                    ref="repaymentHistory"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </vue-custom-scrollbar>
        <div
            id="footer"
            class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh"
            style="background-color: #e9ecef"
        >
            <ts-button
                @click.prevent="$router.push({ name: 'loan-repayment' })"
                class="btn-gray"
            >
                {{ $t('cancel') }}</ts-button
            >
            <ts-button
                color="primary"
                outline
                :waiting="waiting_new"
                :disabled="waiting"
                @click.prevent="onSaveAddNew"
            >
                {{ $t('saveAddNew') }}</ts-button
            >
            <ts-button
                color="primary"
                :waiting="waiting"
                :disabled="waiting_new"
                @click.prevent="onSave"
            >
                {{ $t('save') }}</ts-button
            >
        </div>
    </div>
</template>

<script>
import AppOptions from '@/config/AppOptions.vue'
import { mapActions } from 'vuex'
import { Errors } from 'form-backend-validation'
import moment from 'moment'
import repaymentInfo from './components/repayment-info.vue'
import CustomerProfile from './components/customer-profile.vue'
import RepaymentHistory from './components/repayment-history.vue'
import LoanInfo from './components/loan-info.vue'

export default {
    name: 'loanRepaymentCreate',
    components: {
        repaymentInfo,
        CustomerProfile,
        RepaymentHistory,
        LoanInfo
    },
    data () {
        return {
            loading: false,
            waiting: false,
            waiting_new: false,
            errors: new Errors(),
            model: {
                loan_id: null,
                actual_payment_date: moment().format('DD-MM-YYYY'),
                cycle_payment_date: moment().format('DD-MM-YYYY'),
                amount_paid: null,
                penalty_waive: null,
                input_source: 'W',
                payment_type: 1,
                device_info: null
            }
        }
    },
    created () {
        AppOptions.appContentFullHeight = true
        AppOptions.appContentClass = 'p-0'
    },
    methods: {
        ...mapActions('creditOperation/loanRepayment', ['getLoanType']),
        async fetchResource () {
            this.loading = true
            await this.$refs.repaymentInfo.loadLoanDisbursement()
            this.loading = false
        },
        onSaveAddNew () {
            this.errors = new Errors()
            this.waiting_new = true
            this.$store
                .dispatch('creditOperation/loanRepayment/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.clearInput()
                    this.$refs.repaymentInfo.loadLoanDisbursement()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('creditOperation/loanRepayment/store', this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.clearInput()
                    this.$router.push({ name: 'loan-repayment' })
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        clearInput () {
            this.errors = new Errors()
            ;(this.model.actual_payment_date = moment().format('DD-MM-YYYY')),
                (this.model.cycle_payment_date = moment().format('DD-MM-YYYY')),
                (this.model.loan_id = null),
                (this.model.amount_paid = null),
                (this.model.penalty_waive = null),
                (this.model.payment_type = 1),
                (this.model.input_source = 'W'),
                (this.$refs.repaymentInfo.repaymentItems = []),
                this.$store.commit('creditOperation/loanRepayment/RESET_STATE')
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'LOAN REPAYMENT',
                desc: not.text
            })
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchResource()
        })
    },
    beforeRouteLeave (to, from, next) {
        AppOptions.appContentFullHeight = false
        AppOptions.appContentClass = ''
        this.$store.commit('creditOperation/loanRepayment/RESET_STATE')
        this.$store.commit('creditOperation/loanRepayment/CLEAR_RESOURCES')
        next()
    }
}
</script>
